import { SupabaseModel } from '@/lib/supabase/queries';
import { MODELS } from '@/lib/types';
import { cn, formatDollars } from '@/lib/utils';
import { Accordion } from '@radix-ui/react-accordion';
import { ArrowUpRightFromSquare, ChevronsUpDown, Lightbulb } from 'lucide-react';
import Image from 'next/image';
import { forwardRef, useMemo, useState } from 'react';
import { FairLogoText } from '../fair-logo';
import { ShortcutIcon } from '../shortcut-icon';
import { AccordionContent, AccordionItem, AccordionTrigger } from '../ui/accordion';
import { Button } from '../ui/button';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '../ui/dialog';
function LearnMoreAboutTokens() {
  return <Accordion type="multiple" data-sentry-element="Accordion" data-sentry-component="LearnMoreAboutTokens" data-sentry-source-file="model-picker.tsx">
      <AccordionItem value="1" className="border-none" data-sentry-element="AccordionItem" data-sentry-source-file="model-picker.tsx">
        <AccordionTrigger className="flex items-center gap-1 py-1" data-sentry-element="AccordionTrigger" data-sentry-source-file="model-picker.tsx">
          Learn more about tokens
        </AccordionTrigger>
        <AccordionContent className="rounded-md border px-2 py-1 text-sm text-muted-foreground" data-sentry-element="AccordionContent" data-sentry-source-file="model-picker.tsx">
          <p className="pb-1 text-foreground">What are tokens?</p>
          <p>
            LLMs work on a per-token basis. Every time you send a message, the
            chat history is sent to an LLM of your choice, and it computes the
            response.
          </p>
          <p className="pb-1 pt-2 text-foreground">How do we count tokens?</p>
          <p>
            In <FairLogoText data-sentry-element="FairLogoText" data-sentry-source-file="model-picker.tsx" />, you only pay for what you use; the more tokens
            you send or recieve, the more credits will be deducted from your
            account.
          </p>
          <p className="pb-1 pt-2 text-foreground">
            How many tokens are there in a message?
          </p>
          <p>
            1 000 000 tokens is approximately 750 000 words. For example, the 7
            books of Harry Potter are around 1,084,170 words.
          </p>
        </AccordionContent>
      </AccordionItem>
    </Accordion>;
}
function Pricing({
  model
}: {
  model: SupabaseModel;
}) {
  return model.pricing && model.pricing.per_input_token + model.pricing.per_output_token + model.pricing.per_message + model.pricing.per_month > 0 ? <ul className="list-disc pl-4 text-sm text-muted-foreground">
      {model.pricing.per_input_token > 0 && <li>
          <span className="font-mono">
            {formatDollars(model.pricing.per_input_token * 1000000, {
          fixed: true
        })}{' '}
          </span>
          per million input tokens
        </li>}
      {model.pricing.per_output_token > 0 && <li>
          <span className="font-mono">
            {formatDollars(model.pricing.per_output_token * 1000000, {
          fixed: true
        })}{' '}
          </span>
          per million output tokens
        </li>}
      {model.pricing.per_message > 0 && <li>
          <span className="font-mono">
            {formatDollars(model.pricing.per_message, {
          decimals: 4,
          fixed: true
        })}{' '}
          </span>
          per message
        </li>}
      {model.pricing.per_month > 0 && <li>
          <span className="font-mono">
            {formatDollars(model.pricing.per_month, {
          fixed: true
        })}
          </span>
          per month
        </li>}
    </ul> : <p>
      Pricing:
      <span className="mx-2 rounded-full bg-green-500 px-2 py-1 text-xs text-white">
        FREE
      </span>
    </p>;
}
function ProTip() {
  return <p className="hidden items-center justify-center gap-2 text-xs text-muted-foreground sm:flex" data-sentry-component="ProTip" data-sentry-source-file="model-picker.tsx">
      <Lightbulb className="size-4 shrink-0" data-sentry-element="Lightbulb" data-sentry-source-file="model-picker.tsx" />
      <span className="font-bold">Pro Tip:</span>
      Use the shortcut <ShortcutIcon cmd data-sentry-element="ShortcutIcon" data-sentry-source-file="model-picker.tsx">M</ShortcutIcon>
      to open the model picker.
    </p>;
}
type ModelPickerProps = {
  model?: SupabaseModel | undefined;
  setModel: (model: SupabaseModel | undefined) => void;
  availableModels: SupabaseModel[];
  className?: string;
  required?: boolean;
  title?: string;
  avoidPortal?: boolean;
};
export const ModelPicker = forwardRef<HTMLButtonElement, ModelPickerProps>(({
  model,
  setModel,
  availableModels,
  className,
  required,
  title = 'Choose a model',
  avoidPortal
}, ref) => {
  const [open, setOpen] = useState(false);
  const groupedModels = useMemo(() => MODELS.map(m => availableModels.find(a => a.name === m)).filter(a => !!a).reduce((acc, m) => {
    const index = acc.findIndex(a => a[0].provider === m.provider);
    if (index !== -1) {
      acc[index].push(m);
    } else {
      acc.push([m]);
    }
    return acc;
  }, [] as SupabaseModel[][]), [availableModels]);
  return <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button ref={ref} variant="outline" role="combobox" aria-expanded={open} className={cn('transition-all', className)}>
            {model && availableModels.find(m => m.id === model.id) ? <>
                {model.icon && <Image src={model.icon} width={20} height={20} alt={model.name} className="overflow-hidden rounded-full" />}
                {model.name}
              </> : 'No LLM'}
            <ChevronsUpDown className="ms-auto size-4 shrink-0 opacity-50" />
          </Button>
        </DialogTrigger>
        <DialogContent
    // We want to focus on the text area, so disabling the default behavior
    onCloseAutoFocus={e => e.preventDefault()} className="max-w-3xl" avoidPortal={avoidPortal}>
          <DialogHeader>
            <DialogTitle className="text-2xl">{title}</DialogTitle>
          </DialogHeader>
          {groupedModels.map(models => <div key={models[0].provider} className="mt-2 flex flex-col gap-6">
              <h3 className="text-lg font-semibold">{models[0].provider}</h3>
              <div className="flex flex-col gap-6 *:flex-1 sm:flex-row sm:gap-2">
                {models.map(m => <button key={m.id} onClick={() => {
            setModel(model?.name === m?.name ? undefined : availableModels.find(m2 => m2.name === m.name) || model);
            setOpen(false);
          }} disabled={required && m.name === model?.name} className={cn('relative flex flex-col items-stretch justify-start gap-2 rounded-lg border p-4 pt-5 text-start shadow transition-all focus-visible:outline-offset-4 focus-visible:outline-fuchsia-500/70 hover:enabled:shadow-lg', m.name === model?.name ? 'border-foreground' : '')}>
                    <div className="absolute left-4 top-0 flex -translate-y-1/2 flex-wrap gap-1">
                      {m.tags.map(t => <span key={t} className={cn('rounded-full border bg-background px-2.5 py-1 text-xs font-semibold', t === 'NEW' && 'border-foreground bg-foreground text-background')}>
                          {t}
                        </span>)}
                    </div>
                    <div className="flex items-center gap-2">
                      <div className="border-1 size-4 rounded-full border border-foreground p-0.5">
                        {m.name === model?.name && <div className="size-full rounded-full bg-foreground" />}
                      </div>
                      <h4 className="py-1 text-xl font-bold">{m.name}</h4>
                      {m.link && <a href={m.link} className="ms-auto flex items-center gap-1 text-sm text-blue-500" target="_blank" rel="noreferrer" onClick={e => e.stopPropagation()} tabIndex={-1}>
                          Learn more
                          <ArrowUpRightFromSquare className="size-4 shrink-0" />
                        </a>}
                    </div>
                    <p className="text-muted-foreground">{m.description}</p>
                    {m.context_window && <p className="text-sm text-muted-foreground">
                        <span className="me-1 font-bold">
                          {m.context_window / 1000}k
                        </span>
                        context window
                      </p>}
                    <Pricing model={m} />
                  </button>)}
              </div>
            </div>)}
          <LearnMoreAboutTokens />
          <ProTip />
        </DialogContent>
      </Dialog>;
});
ModelPicker.displayName = 'ModelPicker';