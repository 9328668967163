import { ArrowBigUp, Command } from 'lucide-react';
import { useEffect, useState } from 'react';
export function ShortcutIcon({
  children,
  cmd,
  shift
}: {
  children: React.ReactNode;
  cmd?: boolean;
  shift?: boolean;
}) {
  const [isMac, setIsMac] = useState(false);
  useEffect(() => {
    setIsMac(navigator.userAgent.toLowerCase().includes('mac'));
  }, []);
  return <span className="flex items-center whitespace-pre rounded-lg border bg-muted px-1.5 py-0.5 font-mono font-medium text-orange-500 shadow-md" data-sentry-component="ShortcutIcon" data-sentry-source-file="shortcut-icon.tsx">
      {cmd && (isMac ? <Command className="me-1 size-3 shrink-0" /> : <span>Ctrl + </span>)}
      {shift && <ArrowBigUp className="me-1 size-3 shrink-0" />}
      <span>{children}</span>
    </span>;
}